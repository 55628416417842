'use client';

import React from 'react';
import Image from 'next/image';
import imagePaths from '../imagePaths.json'; // Adjust the import path as necessary

interface ImageGalleryProps {
  currentIndex: number;
  handleNext: () => void;
  handlePrev: () => void;
  setCurrentIndex: (index: number) => void;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({
  currentIndex,
  setCurrentIndex,
  handleNext,
  handlePrev,
}) => {
  return (
    <div className="w-full mx-auto mt-10">
      <div className="w-full overflow-hidden h-[286px] md:h-[600px] relative items-center justify-center">
        {/* Carousel items */}
        <div className="w-full h-full relative">
          {imagePaths.map((imagePath, index) => (
            <div
              key={index}
              className={`absolute inset-0 transition-opacity duration-500 flex items-center justify-center ${currentIndex === index ? 'opacity-100' : 'opacity-0'}`}
            >
              <Image
                src={imagePath}
                alt={`Slide ${index}`}
                width={1920}
                height={600}
                style={{
                  height: 'auto',
                  width: '100%',
                }}
              />
            </div>
          ))}
        </div>

        {/* Carousel indicators */}
        <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
          {imagePaths.map((_, index) => (
            <button
              key={index}
              className={`h-2 w-2 rounded-full ${currentIndex === index ? 'bg-white' : 'bg-gray-400'}`}
              onClick={() => setCurrentIndex(index)}
            ></button>
          ))}
        </div>

        {/* Carousel controls */}
        <button
          className="hidden md:flex absolute top-1/2 left-0 transform -translate-y-1/2 h-10 w-10 items-center justify-center bg-black bg-opacity-50 text-white rounded-full z-10"
          onClick={handlePrev}
        >
          &lt;
        </button>
        <button
          className="hidden md:flex absolute top-1/2 right-0 transform -translate-y-1/2 h-10 w-10 items-center justify-center bg-black bg-opacity-50 text-white rounded-full z-10"
          onClick={handleNext}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default ImageGallery;
